<template>
  <v-container fluid>
    <head-title
      text="Nuevo Registro de Consumo de Diésel"
      :to="{ name: 'diesel-list' }"
    ></head-title>
    <v-card-text>
      <v-container>
        <p v-if="responseError && !valid">
          Los datos enviados no son correctos, haga click sobre la X de los campos
          inválidos para intentar de nuevo.
        </p>
        <v-form ref="myform" v-model="valid" :lazy-validation="false">
          <v-row>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                ref="date"
                label="Fecha"
                v-model="setDate"
                :rules="onlyRequired"
                type="date"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                label="Hora"
                v-model="setTime"
                :rules="onlyRequired"
                type="time"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                label="Descripción"
                v-model="body.vehicle_id"
                :rules="textRule"
                :error-messages="errorVehicleId"
                @click:clear="errorVehicleId = []"
                clearable
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-text-field
                label="Contador"
                v-model="body.horometer"
                :rules="onlyRequired"
                :error-messages="errorHorometer"
                @click:clear="errorHorometer = []"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="1">
              <v-text-field
                label="Galones"
                v-model="body.quantity"
                :rules="valueRules"
                :error-messages="errorQuantity"
                @clearable="errorQuantity = []"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                label="Conductor"
                v-model="body.driver"
                :rules="onlyRequired"
                :error-messages="errorDriver"
                @click:clear="errorDriver = []"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-autocomplete
                ref="auto"
                placeholder="Seleccione un Centro de Costo"
                label="Centro de Costo"
                item-text="description"
                item-value="id"
                :items="costs"
                v-model="body.catalog_id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn
                color="blue darken-1"
                text
                @click="save()"
                :loading="load"
                :disabled="!valid"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="list"
      class="elevation-2"
      hide-default-footer
      :loading="loading"
      disable-pagination
      disable-sort
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.dispensed | datef }}
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ item.dispensed | time }}
      </template>
      <template v-slot:[`item.horometer`]="{ item }">
        {{ item.horometer | currency }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
  },
  data() {
    return {
      check: false,
      load: false,
      valid: false,
      loading: false,
      setTime: undefined,
      setDate: undefined,
      today: undefined,
      body: {
        quantity: 0,
        horometer: undefined,
        driver: undefined,
        vehicle_id: undefined,
        dispensed: undefined,
        sucursal: undefined,
        catalog_id: undefined,
      },
      responseError: false,
      errorQuantity: [],
      errorHorometer: [],
      errorDriver: [],
      errorVehicleId: [],
      errorDispensed: [],
      errorCatalog: [],
      vehicles: [],
      costs: [],
      list: [],
      headers: [
        { text: "Fecha", value: "date" },
        { text: "Hora", value: "time" },
        { text: "ID Vehículo", value: "vehicle_id" },
        { text: "Piloto", value: "driver" },
        { text: "Contador", value: "horometer", align: "right" },
        { text: "Galones", value: "quantity", align: "right" },
        { text: "Centro de Costo", value: "catalog.description", align: "right" },
      ],

      //rules
      onlyRequired: [rules.required],
      valueRules: [rules.required, rules.minValue(0.1)],
      textRule: [rules.maxLength(60)],
    };
  },
  methods: {
    vehicleList() {
      requests.get("/api/catalogs/vehicles/").then((res) => {
        this.vehicles = res.data.results;
      });
    },
    getCatalogCost() {
      requests.get("/api/catalogs/catalog/?name=CENTRO&centro=true").then((res) => {
        this.costs = res.data;
      });
    },
    save() {
      this.valid = false;
      this.load = true;
      this.combineDateAndTime(this.setDate, this.setTime);
      if (this.body.dispensed != undefined) {
        if (this.$refs.myform.validate()) {
          requests.post("/api/fleet/", this.body).then((res) => {
            if (res.status == 201) {
              this.$toasted.global.info({
                message: "Operación realizada con éxito.",
              });
              this.clean();
              this.getData();
            } else if (res.status == 400) {
              if (res.data.detail != undefined) {
                this.$toasted.global.info({ message: res.data.detail });
              }
              if (res.data.dispensed != undefined) {
                this.responseError = true;
                this.errorDispensed = res.data.dispensed;
              }
              if (res.data.quantity != undefined) {
                this.responseError = true;
                this.errorQuantity = res.data.quantity;
              }
              if (res.data.horometer != undefined) {
                this.responseError = true;
                this.errorHorometer = res.data.horometer;
              }
              if (res.data.vehicle_id != undefined) {
                this.responseError = true;
                this.errorVehicleId = res.data.vehicle_id;
              }
              if (res.data.driver != undefined) {
                this.responseError = true;
                this.errorDriver = res.data.driver;
              }
              if (res.data.date != undefined) {
                this.responseError = true;
                this.$toasted.global.info({ message: res.data.date });
              }
            } else {
              console.log(res);
            }
          });
        }
      }
      this.load = false;
    },
    getData() {
      this.loading = true;
      requests.get("api/fleet/?date=" + this.today + "&limit=10").then((res) => {
        this.list = res.data.results;
      });
      this.loading = false;
    },
    clean() {
      this.$refs.myform.reset();
      this.responseError = false;
      this.errorQuantity = [];
      this.errorHorometer = [];
      this.errorDriver = [];
      this.errorVehicleId = [];
      this.errorDispensed = [];
      this.$refs.date.focus();
    },
    combineDateAndTime(date, time) {
      // this.body.dispensed = date + "T" + time + ":00-06:00";
      this.body.dispensed = date + "T" + time;
    },
  },
  mounted() {
    this.today = new Date().toISOString().substr(0, 10);
    this.vehicleList();
    this.getData();
    this.getCatalogCost();
    this.$refs.date.focus();
  },
};
</script>
